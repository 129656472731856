import { useState, useMemo, useEffect, useRef, useContext } from "react";
import SignIn from "src/components/SignIn.js";
import CenterDialog from "./CenterDialog.js";
import { Typography, Avatar } from "@mui/material";
import { useProfileImage } from "src/utils/useProfileImage.js";
import { useGetCalendarsByHandle } from "src/services/index.js";
import { CalendarToday } from "@mui/icons-material";
import LazyImageComponent from "../LazyImageComponent.js";
import { AuthContext } from "src/utils/AuthContext.js";
import { throttle } from "lodash";

const SCROLL_TRIGGER_PERCENTAGE = 2;

const SignInDialog = ({
  open,
  handleClose,
  calendar = null,
  openOnScroll = false,
}) => {
  const handle = calendar?.handle;
  const { data: dataCalendars } = useGetCalendarsByHandle({ handle }, {});
  const profileImage = useProfileImage(
    useMemo(() => dataCalendars?.creator, [dataCalendars])
  );
  const { user } = useContext(AuthContext);
  const [isScrolledEnough, setIsScrolledEnough] = useState(false);

  const dialogId = `signin-dialog${openOnScroll ? "-scrollable" : ""}`;
  const dialogRef = useRef(null);
  const backdropRef = useRef(null);

  const openOnScrollProps = {
    sx: {
      height: "100vh",
      minHeight: "100vh",
      willChange: "transform",
      transform: isScrolledEnough ? "translateY(0)" : "translateY(100vh)",
      // delay strategy with 100ms more than paper and backdrop transition
      transition: !isScrolledEnough ? "transform 0s linear 0.5s" : "",
    },
    openOnScroll,
    PaperProps: {
      ref: dialogRef,
      sx: {
        position: "fixed",
        willChange: "transform",
        pointerEvents: "auto",
        margin: 0,
        width: "100%",
        maxWidth: "400px",
        transform: isScrolledEnough ? "translateY(0)" : "translateY(100vh)",
        transition: "transform 0.4s ease, opacity 0.4s ease",
      },
    },
    BackdropProps: {
      ref: backdropRef,
      style: {
        opacity: isScrolledEnough ? 1 : 0,
        willChange: "transform",
        transform: isScrolledEnough ? "translateY(0)" : "translateY(100vh)",
        transition: "opacity 0.4s ease",
      },
    },
    disableScrollLock: true,
  };

  const handleScroll = useMemo(
    () =>
      throttle(() => {
        requestAnimationFrame(() => {
          const scrollTop = window.pageYOffset;
          const windowHeight = window.innerHeight;
          const scrollPercentage = scrollTop / windowHeight;

          if (
            dialogRef.current &&
            backdropRef.current &&
            !document.getElementById("signin-dialog")
          ) {
            if (scrollPercentage >= SCROLL_TRIGGER_PERCENTAGE) {
              setIsScrolledEnough(true);
            } else {
              setIsScrolledEnough(false);
            }
          }
        });
      }, 30),
    []
  );

  const handleCloseDialog = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    handleClose();
  };

  useEffect(() => {
    if (openOnScroll && open) {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [openOnScroll, open]);

  useEffect(() => {
    if (user) {
      handleClose();
    }
  }, [user]);

  if (calendar) {
    const SignInHeadContent = () => {
      return (
        <>
          <LazyImageComponent
            sx={{
              mx: "auto",
              mt: 0.5,
              borderRadius: "999px",
              width: "50px",
              height: "50px",
            }}
            showOnError
          >
            <Avatar
              src={profileImage}
              sx={{
                mx: "auto",
                mt: 0.5,
                width: "50px",
                height: "50px",
                placeContent: "center",
              }}
            >
              <CalendarToday />
            </Avatar>
          </LazyImageComponent>
          <Typography variant={"h5"} sx={{ mt: 1, mb: 0.5 }}>
            Discover more from
          </Typography>
          <Typography variant={"h2"} sx={{ mb: 2 }}>
            {calendar.name}
          </Typography>

          <Typography
            variant={"subtitle2"}
            sx={{ mb: 1 }}
            color="text.secondary"
          >
            Get the latest events in your calendar.
          </Typography>
          <Typography
            variant={"subtitle2"}
            sx={{ mb: 3 }}
            color="text.secondary"
          >
            {calendar?.subscribers >= 100
              ? `${
                  calendar.subscribers >= 1000
                    ? new Intl.NumberFormat("en-US", {
                        notation: "compact",
                      }).format(calendar.subscribers)
                    : calendar.subscribers
                } ${
                  calendar.subscribers >= 1000 ? "subscribers" : "subscribers"
                }`
              : ""}
          </Typography>
        </>
      );
    };
    return (
      <CenterDialog
        id={dialogId}
        data-testid={dialogId}
        open={open}
        handleClose={openOnScroll ? handleCloseDialog : handleClose}
        hideCloseButton
        {...(openOnScroll ? openOnScrollProps : {})}
        sx={{
          "& .MuiPaper-root": {
            width: { xs: "80%", md: "auto" },
          },
          "& .MuiDialogContent-root": { width: { md: "330px" } },
          ...(openOnScroll ? openOnScrollProps.sx : {}),
        }}
      >
        <SignIn dialog headContent={<SignInHeadContent />} />
      </CenterDialog>
    );
  }

  return (
    <CenterDialog
      id={dialogId}
      data-testid={dialogId}
      open={open}
      handleClose={handleCloseDialog}
      {...openOnScrollProps}
    >
      <SignIn dialog />
    </CenterDialog>
  );
};

export default SignInDialog;
