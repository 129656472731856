import { Avatar, Typography, Box, Button } from "@mui/material";
import { useProfileImage } from "src/utils/useProfileImage.js";
import { formatSubscribers } from "src/utils/Utils.js";
import Stanzalogo from "src/assets/svg/stanza-logo.js";

// Profile component oriented to show calendar information
export function ProfileCard({ creator, calendarName, handleAddToCalendar }) {
    const profileImage = useProfileImage(creator);
    return (
        <Box id="calendar-card-box" display="flex" flexDirection="column" alignItems="center" p={4}>
            <Avatar src={profileImage} id="avatar-profile-img" data-testid="avatar-profile-img" alt={creator?.name} sx={{ minWidth: 39, minHeight: 39 }} />
            <Typography variant="h2" id="avatar-calendar-name" data-testid="avatar-calendar-name" sx={{ mt: 1, textAlign: "center" }}>{calendarName}</Typography>
            {formatSubscribers(creator?.subscribers) && (
                <Typography variant="subtitle2" color="text.secondary" data-testid="avatar-calendar-count">
                    {formatSubscribers(creator?.subscribers)}
                </Typography>
            )}
            <Button variant="contained" size="shrinked" sx={{ width: "176px", mt: 2 }} onClick={handleAddToCalendar}>
                Add to Calendar
            </Button>
            <Box display="flex" alignItems="center" gap="6px" mt={2}>
                <Typography variant="caption" color="text.secondary">POWERED BY</Typography>
                <Stanzalogo width="68.5px" style={{ transform: "translateY(-0.5px)" }} />
            </Box>
        </Box>
    );
}