import { useState, useContext, memo, useCallback, useMemo, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardMedia,
  CardHeader,
  Typography,
  Button,
  Box,
  CardActions,
  IconButton,
  Tooltip,
} from "@mui/material";
import { getApiRoute, getPageRoute } from "src/services";
import EventDate from "./event/EventDate.js";
import TvBadge from "./event/TvBadge.js";
import TeamLogos from "./event/TeamLogos.js";
import LazyImageComponent from "./LazyImageComponent.js";
import DefaultImage from "./event/DefaultImage.js";
import LiveBadge from "./event/LiveBadge.js";
import UploadPhotoButton from "./event/UploadPhotoButton.js";
import EventActions from "./event/EventActions.js";
import { useNavigate, useSearchParams } from "react-router-dom";
import SubscribeButton from "./SubscribeButton.js";
import { AuthContext } from "src/utils/AuthContext.js";
import { fetchAndCacheImage, handleSignInDialog, redirectSignedOutUser } from "src/utils/Utils.js";
import { useSnackbarContext } from "src/utils/SnackbarContext.js";
import { useTheme } from "@emotion/react";
import { useDimensions } from "src/utils/useDimensions.js"
import { formatSubscribers, formatDate, downloadIcsFileFromjCal } from "src/utils/Utils.js";
import { EventComposer } from "src/utils/EventComposer.js";
import RecommendedCalendars from "./RecommendedCalendars.js";
import EventProfileBg from "./event/EventProfileBg.js";
import EditEventDialog from "./dialogs/EditEventDialog.js";
import { EditOutlined } from "@mui/icons-material";
import SignInDialog from "./dialogs/SignInDialog.js";

function Event({ event, eventData, eventUI, showUpload, calendar, addedCalendars,
  calendarId, type, position, onSubscribeAction, isEmbedded, eventLinks, refetchCalendarEvents, refetchCalendars }) {

  const {
    eventId,
    eventSummary,
    eventLocation,
    eventDateStart,
    eventTvSeasonEpisode,
    formattedDescription,
    isEventLive,
    isPastEvent
  } = eventData
  const { ticketUrl, shopUrl, watchUrl } = eventLinks;
  const {
    homeTeamLogo,
    awayTeamLogo,
    background,
    imageUrl,
    handleImageError,
    setImageUrl,
    isSubscribed,
    readMore,
    setReadMore,
    needsReadMore,
  } = eventUI;

  const { jCal } = event;
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const snackbarContext = useSnackbarContext();
  const { setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, showSnackBar } = snackbarContext
  const theme = useTheme();
  const { isMediumScreen } = useDimensions();
  const [showRecommendedCalendars, setShowRecommendedCalendars] = useState(false);
  const [openEditEventDialog, setOpenEditEventDialog] = useState(false);
  const [openSignInDialog, setOpenSignInDialog] = useState(false);
  const isOwnEvent = user?.handle === calendar?.handle;
  const isGroup = Boolean(calendar?.calendars);
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (eventId) {
      setShowRecommendedCalendars(false);
    }
  }, [eventId]);

  const openEmbeddedEvent = useCallback(() => {
    window.open(getPageRoute("calendar", "CALENDAR_EVENTS", { handle: calendar.handle, calendarId }))
  }, [calendar.handle, calendarId]);

  // Handle the download of the .ics file
  const handleAddEvent = useCallback(() => {
    if (isEmbedded) return openEmbeddedEvent();
    if (handleSignInDialog(user, setOpenSignInDialog, setSearchParams)) return;

    if (user && !user.hasValidSubscription) {
      return navigate(
        getPageRoute(
          "upgrade",
          "UPGRADE_PLUS",
          {},
          {
            forward: window.location.pathname + window.location.search,
          }
        )
      );
    } else {
      downloadIcsFileFromjCal(jCal, eventSummary);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmbedded, jCal, user]);

  // Handle the upload of the image
  const handleImageUpload = useCallback(async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("eventImage", file);
    try {
      const response = await axios.post(
        getApiRoute("image", "UPDATE_IMAGE_URL", {
          calendarId,
          eventId,
        }),
        formData,
        { withCredentials: true }
      );
      setImageUrl(
        response.data.imageUrl + "?timestamp=" + Date.now()
      );
      showSnackBar(setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, 'Image updated successfully.', 'success');
    } catch (error) {
      console.error(error);
      showSnackBar(setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, error.message, 'error');
    }
  }, [calendarId, eventId, setImageUrl, setSnackbarMessage, setSnackbarOpen, setSnackbarSeverity, showSnackBar]);

  const isTrendingOrAdded = type === "trending" || type === "added";

  const [backgroundImage, setBackgroundImage] = useState(null);
  const [loadingBackground, setLoadingBackground] = useState(true);
  useEffect(() => {
    if (!background) return;
    const func = async () => {
      await fetchAndCacheImage(background, setBackgroundImage);
      setLoadingBackground(false);
    }
    func();
  }, [background]);

  // Display individual event
  return (
    <>
      {isTrendingOrAdded && isSubscribed && (
        <RecommendedCalendars sourceCalendars={addedCalendars} onSubscribeAction={null} displayMode={user?.hasValidGoogleConnection ? "addedViaGoogleConnection" : "added"} />
      )}
      <Box data-testid={`${type}-event-${calendarId}`} display="flex" mb={4} mt={2} gap="1rem" sx={{
        '&:not(:first-of-type) > div:nth-of-type(1)': { pt: "2.25rem" },
        '&:not(:first-of-type) > div:nth-of-type(2)': { pt: "2.25rem", borderTop: `1px solid ${theme.palette.text.tertiary}` }
      }}>
        <EventDate eventDateStart={eventDateStart} isTrendingOrAdded={isTrendingOrAdded} />
        <Card id="event-card" variant="outlined" sx={{ width: "100%", border: "none", overflow: "visible" }}>
          {isTrendingOrAdded && (<Box sx={{ py: 0.5, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Button variant="text" onClick={() => navigate(`/${calendar.handle}/${calendarId}`)} sx={{ pl: 0 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: "100%" }}>
                <Typography variant="subtitle1" textTransform="none" data-testid="calendar-name" sx={{ overflow: "hidden", textOverflow: "ellipsis", maxWidth: { xs: "40vw", md: "calc(600px * 0.6)" }, textAlign: 'left' }}>
                  {calendar.name}
                </Typography>
                <Typography variant="subtitle2" color="text.secondary" textTransform="none">{formatSubscribers(calendar?.subscribers, false)}</Typography>
              </Box>
            </Button>
            <SubscribeButton
              user={user}
              calendar={calendar}
              handle={calendar.handle}
              onSubscribeAction={onSubscribeAction}
              isEventCard={true}
              eventId={eventId}
            />
          </Box>
          )}
          <Box id="image-event-box" position="relative" sx={{ cursor: 'pointer' }}
           {...isTrendingOrAdded ? {
             onClick: () => { 
                 navigate(`/${calendar.handle}/${calendarId}`); 
              }, 
            } : {
             onClick: () => {
              if (!user) {
                setOpenSignInDialog(true); 
              }
             }
            }}
           >
            {imageUrl ? (
              <LazyImageComponent>
                <CardMedia
                  data-testid={`image-event-img-${calendarId}`}
                  style={{ borderRadius: "4px", filter: isPastEvent ? 'grayscale(.8)' : 'none' }}
                  component="img"
                  height={isMediumScreen ? "140" : "208"}
                  width="100%"
                  image={imageUrl}
                  onError={handleImageError}
                  loading="eager"
                  alt={
                    eventSummary
                      ? eventSummary[3]
                      : ""
                  }
                />
              </LazyImageComponent>
            ) : (homeTeamLogo && awayTeamLogo) ? (
              <TeamLogos data-testid={`image-event-img-${calendarId}`} team1Logo={homeTeamLogo} team2Logo={awayTeamLogo} alt={calendar.name} height={isMediumScreen ? "140px" : "208px"} style={{ filter: isPastEvent ? 'grayscale(.8)' : 'none' }} width="100%" />
            ) : (background && eventTvSeasonEpisode) ? (
              <EventProfileBg loading={loadingBackground} profileImage={backgroundImage} eventSummary={eventSummary} height={isMediumScreen ? "140px" : "208px"} width="100%" backgroundFullHeight={true} />
            ) : (background) ? (
              <LazyImageComponent>
                <CardMedia
                  data-testid={`image-event-img-${calendarId}`}
                  style={{ borderRadius: "4px" }}
                  component="img"
                  height={isMediumScreen ? "140" : "208"}
                  width="100%"
                  image={background}
                  onError={handleImageError}
                  loading="eager"
                  alt={
                    eventSummary
                      ? eventSummary[3]
                      : ""
                  }
                />
              </LazyImageComponent>
            ) : (
              <DefaultImage
                calendar={calendar}
                height={isMediumScreen ? "140px" : "208px"}
                data-testid={`image-event-img-${calendarId}`}
                src=""
                width="100%"
                id={eventId || "0"}
                position={position}
              />
            )}
            {showUpload && (
              <UploadPhotoButton
                id="image-upload-button"
                data-testid={`image-upload-button-${calendarId}`}
                calendarId={calendarId}
                handleImageUpload={handleImageUpload}
              />
            )}
            {((!!eventTvSeasonEpisode && background && !loadingBackground) || (!!eventTvSeasonEpisode && !background)) ? (
              <TvBadge data-testid={`calendar-event-tv-${position}`} seasonEpisode={eventTvSeasonEpisode} />
            ) : <></>}
            {isEventLive && (<LiveBadge data-testid={`calendar-event-live-${position}`} />)}
          </Box>
          <Typography id="calendar-event-location" data-event={isPastEvent ? "past" : "upcoming"}
            {...isTrendingOrAdded ? { onClick: () => navigate(`/${calendar.handle}/${calendarId}`) } : {}}
            variant="subtitle2" color="text.primary" gutterBottom sx={isTrendingOrAdded ? { my: 1, ...theme.clampLines(1) } : { mt: 2, mb: 1, ...theme.clampLines(1) }}>
            {eventDateStart
              ? formatDate(
                eventDateStart[3],
                snackbarContext
              )
              : ""}
            {eventLocation?.[3] && eventLocation[3] !== "undefined" && eventLocation[3].length > 0 &&
              " · " + eventLocation[3].toLocaleString()
            }
          </Typography>
          <CardHeader
            title={
              eventSummary
                ? eventSummary[3]
                : ""
            }
            sx={{ p: 0, mb: 1, cursor: isTrendingOrAdded ? 'pointer' : 'default', ...theme.clampLines(3), "-webkit-box-align": "start" }}
            {...isTrendingOrAdded ? { onClick: () => navigate(`/${calendar.handle}/${calendarId}`) } : {}}
            id="calendar-event-title"
            data-event={isPastEvent ? "past" : "upcoming"}
            data-testid={`calendar-${isPastEvent ? 'past-' : ''}event-title-${position}`}
          />
          <Typography
            as="span"
            variant="subtitle2"
            color="text.secondary"
            id="calendar-event-description"
            dangerouslySetInnerHTML={{
              __html: formattedDescription,
            }}
          />
          {needsReadMore && !readMore && !isEmbedded &&
            <Typography as="span" style={{ cursor: 'pointer', fontSize: '14px' }} onClick={() => { if (!user) { setOpenSignInDialog(true); } else { setReadMore(true); } }}>{' '}more</Typography>}
          <EventActions
            eventId={eventId}
            ticketUrl={ticketUrl}
            shopUrl={shopUrl}
            watchUrl={watchUrl}
            isPastEvent={isPastEvent}
            position={position}
            isTrendingOrAdded={isTrendingOrAdded}
            handleAddEvent={handleAddEvent}
            showRecommendedCalendars={showRecommendedCalendars}
            setShowRecommendedCalendars={setShowRecommendedCalendars}
            isEmbedded={isEmbedded}
            isOwnEvent={isOwnEvent}
            isGroup={isGroup}
            setOpenEditEventDialog={setOpenEditEventDialog}
          />
        </Card>
      </Box>
      {showRecommendedCalendars &&
        <RecommendedCalendars sourceCalendars={[calendar]} onSubscribeAction={null} displayMode="related" />
      }
      {isOwnEvent &&
        <EditEventDialog calendarId={calendarId} refetchCalendarEvents={refetchCalendarEvents} refetchCalendars={refetchCalendars} eventData={eventData} open={openEditEventDialog} setOpen={setOpenEditEventDialog} />
      }
      <SignInDialog
        open={openSignInDialog}
        handleClose={() => setOpenSignInDialog(false)}
        calendar={calendar}
      />
    </>
  );
}

export default memo(EventComposer(Event));
