import React, { useEffect, useRef } from "react";

const Ad = ({ placementName, alias, style }) => {
  const elRef = useRef(null);

  useEffect(() => {
    let placement;
    console.log("[PROSPER] add", placementName);

    window.top.__VM.push((admanager, scope) => {
      placement = scope.Config.get(placementName, alias).display(elRef.current);
    });

    return () => {
      window.top.__VM.push((admanager, scope) => {
        console.log("[PROSPER] removed", placementName);
        admanager.removePlacement(placement.instance());
      });
    };
  }, []);
  return <div style={style} ref={elRef}></div>;
};

export default Ad;

export const handleVenatusNewSession = () => {
  window.top.__VM = window.top.__VM || [];
  window.top.__VM.push(function (admanager, scope) {
    console.log("[PROSPER] New page session. out");
    scope.Instances.pageManager.on(
      "navigated",
      () => {
        // this should trigger every time you consider the content a "new page"
        scope.Instances.pageManager.newPageSession(false);
        console.log("[PROSPER] New page session.");
      },
      false
    );
  });
};
