import React, { useState } from "react";
import { Skeleton } from "@mui/material";

function LazyImageComponent({ children, sx, showOnError = false }) {
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <div>
      {loading && (
        <Skeleton
          id="img-loader"
          animation="wave"
          variant="rectangular"
          sx={{ borderRadius: "4px", ...sx }}
          width={children.props.width}
          height={Number(children.props.height)}
        />
      )}
      {React.cloneElement(children, {
        sx: {
          display: loading ? "none" : "block",
          ...(children.props?.sx ?? {}),
        },
        id: loading ? "img-loading" : "img-loaded",
        onLoad: handleImageLoad,
        ...(showOnError ? { onError: handleImageLoad } : {}),
      })}
    </div>
  );
}
export default LazyImageComponent;
